 
import { useEffect, useState } from "react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";
import { z } from "zod";

import { UserModelOutput } from "~/api/model";
import { useSearchUsersV1UsersGet, useUpdateUserRolesV1UsersUserIdSetUserRolesPatch } from "~/api/users/users.gen";
import { getCountryOptions } from "~/helpers/country-list";
import { parseFastAPIError } from "~/helpers/parse-errors";
import Icon from "~/oldComponents/icon/Icon";
import { ListPage } from "~/oldComponents/layout/ListPage";
import { FormConfig, PageConfig, PaginationConfig, TableConfig } from "~/oldComponents/layout/types";
import { MultiSelect } from "~/oldComponents/select/MultiSelect/MultiSelect";

export const Route = createFileRoute("/_auth/users/admin/")({
  validateSearch: z.object({
    email: z.string().optional(),
    country: z.string().min(2).optional().catch(undefined),
    is_admin: z.boolean().optional().catch(undefined),
    status: z.string().optional(),
    page: z.number().optional().catch(1),
    limit: z.number().optional().catch(100),
  }),
  component: () => <PermissionsPage />,
});

function PermissionsPage() {
  const searchParams = Route.useSearch();
  const navigate = useNavigate({ from: Route.fullPath });

  const [users, setUsers] = useState<UserModelOutput[]>([]);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const [total, setTotal] = useState(0);

  const [tempSearch, setTempSearch] = useState({
    email: searchParams.email,
    country: searchParams.country,
    is_admin: searchParams.is_admin,
  });

  const [search, setSearch] = useState({
    email: searchParams.email,
    country: searchParams.country,
    is_admin: searchParams.is_admin,
    page: searchParams.page,
    limit: searchParams.limit,
  });

  const isDefault = (search: any) => {
    return !search.email && !search.country && !search.is_admin;
  };

  const { data, isFetching, refetch } = useSearchUsersV1UsersGet(
    {
      email: search.email || undefined,
      country: search.country || undefined,
      is_admin: search.is_admin || undefined,
      page: search.page,
      limit: search.limit,
    },
    {
      query: {
        enabled: !isDefault(search),
      },
    },
  );

  useEffect(() => {
    if (data) {
      setUsers(data.items);
      setHasNext(data.has_next);
      setHasPrev(data.has_prev);
      setTotal(data.total);
    } else {
      setUsers([]);
      setHasNext(false);
      setHasPrev(false);
      setTotal(0);
    }
  }, [data]);

  const onPageChange = (page: number) => {
    const newParams = { ...search, page: page };
    navigate({
      search: () => newParams,
    });
    setSearch(newParams);
  };

  const onLimitChange = (limit: number) => {
    const newParams = { ...search, limit: limit };
    navigate({
      search: () => newParams,
    });
    setSearch(newParams);
  };

  const onSearchSubmit = async (event: any) => {
    event.preventDefault();
    setSearch({ ...search, page: 1, ...tempSearch });
  };

  const updateUserRolesMutation = useUpdateUserRolesV1UsersUserIdSetUserRolesPatch({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
      },
      onSuccess: () => {
        toast("User roles was successfully updated!");
        refetch();
      },
    },
  });

  const updateAdminRoles = async (e: any, userId: string) => {
    updateUserRolesMutation.mutate({ userId: userId, data: { roles: e.map((e: any) => e.value) } });
  };

  const page: PageConfig = {
    title: "Permissions",
    description: "Search for current admin users and change their permissions by clicking on the roles",
    addContent: "Add new Admin",
    addLink: "/users/admin/create",
    tableUpload: {
      fileName: "admin-users",
      data: users,
    },
  };

  const table: TableConfig = {
    tableBuild: [
      {
        headerTitle: "Country",
        format: (item: any) => item.profile.address?.country,
      },
      {
        headerTitle: "Name",
        format: (item: any) => `${item.profile?.firstName ?? "-"} ${item.profile?.lastName ?? "-"}`,
      },
      {
        headerTitle: "Email",
        format: (user: any) =>
          user.email ? (
            <span title="Verified email">
              {user.email} <Icon icon="FaCircleCheck" />
            </span>
          ) : (
            user.profile.email || "-"
          ),
      },
      {
        headerTitle: "Phone",
        format: (item: any) => (item.phonenumber ? `${item.phonenumber}` : "-"),
      },
      {
        headerTitle: "Roles",
        actionRow: true,
        format: (item: any) => (
          <MultiSelect
            onChange={(e: any) => updateAdminRoles(e, item._id)}
            value={item.roles.map((value: any) => ({ value: value, label: value }))}
            options={[
              {
                value: "User",
                label: "User",
              },
              {
                value: "Admin",
                label: "Admin",
              },
              {
                value: "Admin_Owner",
                label: "Super Admin",
              },
              {
                value: "Cheater",
                label: "Cheater",
              },
              {
                value: "Possible_Cheater",
                label: "Possible Cheater",
              },
            ]}
            disabled={isFetching}
          />
        ),
      },
    ],
    objects: users,
    link: (item: any) => navigate({ to: "/users/$userId", params: { userId: item._id } }),
    emptyMessage: "There are no admin users in the system yet.",
  };

  const searchBar: FormConfig = {
    name: "permissions-searchbar",
    fields: [
      {
        name: "email",
        label: "Email:",
        helpText: "Searching for email address.",
        type: "text",
        value: tempSearch.email,
        change: (event: any) => setTempSearch({ ...tempSearch, email: event.target.value }),
      },
      {
        name: "country",
        label: "Country:",
        helpText: "Only show users from this country or show all users.",
        type: "search-select",
        options: getCountryOptions(false, "all Countries"),
        value: tempSearch.country || "",
        change: (data: string) => setTempSearch({ ...tempSearch, country: data ? data : undefined }),
      },
      {
        name: "is_admin",
        label: "Is admin:",
        helpText: "If selected, only show admins.",
        type: "select",
        options: [
          {
            value: "",
            name: "All Users",
          },
          {
            value: true,
            name: "Only Admins",
          },
        ],
        value: tempSearch.is_admin || "",
        change: (data: string) => setTempSearch({ ...tempSearch, is_admin: data ? true : undefined }),
      },
    ],
    onSubmit: onSearchSubmit,
    submitText: "Search",
  };

  const pagination: PaginationConfig = {
    page: {
      value: search.page || 1,
      onChange: onPageChange,
    },
    limit: {
      value: search.limit || 100,
      onChange: onLimitChange,
    },
    hasNext: hasNext,
    hasPrevious: hasPrev,
    total: total,
  };

  return <ListPage form={searchBar} pagination={pagination} loading={isFetching} page={page} table={table} />;
}
