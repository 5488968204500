import { useMemo } from "react";
import { createFileRoute, Link } from "@tanstack/react-router";
import { ColumnDef, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";

import { DataTable } from "@/datatable";
import Tab from "@/tab";
import { buttonVariants } from "@/ui/button";
import { Card } from "@/ui/card";

import formatDate from "~/helpers/date-formatting";
import { OfferWithCategoriesAndTags, useMarketplace } from "~/providers/marketplace";

export const Route = createFileRoute("/_auth/$market/marketplace/soldout")({
  component: () => <MarketplacePage />,
});

function MarketplacePage() {
  const { market } = Route.useParams();
  const { almostSoldOutRewards, marketplaceLoading } = useMarketplace();

  const columns: ColumnDef<OfferWithCategoriesAndTags>[] = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        cell: ({ row }) => {
          return <Link to={`/${market}/marketplace/rewards/${row.original.id}`}>{row.original.id}</Link>;
        },
      },
      {
        accessorKey: "fullTitle",
        header: "Title",
        cell: ({ row }) => {
          return <Link to={`/${market}/marketplace/rewards/${row.original.id}`}>{row.original.fullTitle}</Link>;
        },
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "amount",
        header: () => {
          return (
            <div>
              Total Inventory
              <br />
              <small>Available/Limit warning</small>
            </div>
          );
        },
        cell: ({ row }) => {
          return (
            <>
              <div>{row.original.amount}</div>
              <div>
                {row.original.amount - (row.original.verified || 0)}/{row.original.couponWarningLimit || 7}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "unused_coupon_count",
        header: () => {
          return (
            <div>
              Coupons
              <br />
              <small>Unused/Limit warning</small>
            </div>
          );
        },
        cell: ({ row }) => {
          if (!row.original.coupon_count) {
            return "-";
          }
          return (
            <>
              <div>
                {row.original.unused_coupon_count}/{row.original.couponWarningLimit || 7}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "updatedAt",
        header: "Last Updated",
        cell: ({ row }) => {
          if (row.original.updatedAt) {
            return formatDate(row.original.updatedAt);
          }
          return "";
        },
      },
      {
        id: "coupons",
        header: "",
        cell: ({ row }) => {
          return (
            <Link to={`/${market}/marketplace/rewards/${row.original.id}/coupons`} className={buttonVariants({ variant: "outline" })}>
              Coupons
            </Link>
          );
        },
      },
    ],
    [almostSoldOutRewards],
  );

  const table = useReactTable({
    data: almostSoldOutRewards,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: "updatedAt",
          desc: true,
        },
      ],
    },
  });

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section className="flex items-start justify-between">
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Marketplace - Almost sold out</h2>
          <p className="text-foreground">Show a list of Rewards that are almost sold out and need to be restocked</p>
        </div>
        <div className="flex gap-6">
          <Link
            to="/$market/marketplace/rewards/bonus/$rewardId"
            params={{ rewardId: "create", market: market }}
            className={buttonVariants({ variant: "outline" })}
          >
            Create bonus reward
          </Link>
          <Link
            to="/$market/marketplace/rewards/$rewardId"
            params={{ rewardId: "create", market: market }}
            className={buttonVariants({ variant: "default" })}
          >
            Create reward
          </Link>
        </div>
      </section>
      <div>
        <div className="flex w-full gap-2">
          <Tab active={false} to={`/${market}/marketplace`} title="Rewards" />
          <Tab active={false} to={`/${market}/marketplace/collections`} title="Collections" />
          <Tab active={false} to={`/${market}/marketplace/liveview`} title="Live View" />
          <Tab active={true} to={`/${market}/marketplace/soldout`} title={`Sold out | ${almostSoldOutRewards.length}`} />
        </div>
        <Card className="bg-card px-4 py-10 text-card-foreground">
          <DataTable table={table} columns={columns} loading={marketplaceLoading} hidePagination={true} />
        </Card>
      </div>
    </section>
  );
}
