import { useEffect, useMemo, useState } from "react";
import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, useReactTable } from "@tanstack/react-table";
import { toast } from "sonner";

import { Combobox, ComboboxOption } from "@/combobox";
import { DnDDataTable, RowDragHandleCell } from "@/dnd_datatable";
import { Icon } from "@/icon/icon";
import { Button } from "@/ui/button";

import RewardOrderPopup from "../RewardOrder";

import { HighlightedCollectionType } from "~/api/model";
import { getCategoryName } from "~/helpers/category";
import { HighlightedCollectionWithCollection, useMarketplace } from "~/providers/marketplace";

export default function HighlightedCollectionsPart({
  mappedCollections,
  setMappedCollecitons,
}: {
  mappedCollections: HighlightedCollectionWithCollection[];
  setMappedCollecitons: any;
}) {
  const { categories, tags, businessRules, refetchMarketplace, market } = useMarketplace();

  const [mappedOptions, setMappedOptions] = useState<ComboboxOption[]>([]);

  const [localCollections, setLocalCollections] = useState<HighlightedCollectionWithCollection[]>([]);

  useEffect(() => {
    setLocalCollections(mappedCollections);
  }, [mappedCollections]);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState<HighlightedCollectionWithCollection | null>(null);

  useEffect(() => {
    const collectionData = localCollections.map((collection: any) => collection.data);
    const mappedCategories = categories
      .filter((category: any) => !collectionData.includes(category.category))
      .map((category: any) => ({
        value: category.category,
        label: getCategoryName(category.category) || "",
        type: "Category",
      }));

    const mappedTags = tags
      .filter((tag: any) => !collectionData.includes(tag._id))
      .map((tag: any) => ({
        value: tag._id,
        label: tag.name,
        type: "Tag",
      }));

    const mappedRules = businessRules
      .filter((rule) => !collectionData.includes(rule._id))
      .map((rule) => ({
        value: rule._id,
        label: rule.name,
        type: "BusinessRules",
      }));

    setMappedOptions([
      { value: "", label: "Categories", type: "separator" },
      ...mappedCategories,
      { value: "", label: "Tags", type: "separator" },
      ...mappedTags,
      { value: "", label: "Business Rules", type: "separator" },
      ...mappedRules,
    ]);
  }, [categories, tags, localCollections]);

  const createCollectionFn = (option: ComboboxOption): void => {
    if (!option.value) return;

    const newCollections = [...localCollections];
    if (option.type === "Category") {
      const category = categories.find((c) => c.category === option.value);
      newCollections.push({
        order: localCollections.length ? localCollections[localCollections.length - 1].order + 1 : 1,
        data: option.value,
        type: (option.type as HighlightedCollectionType) || HighlightedCollectionType.Category,
        market: market,
        collection: category,
        id: option.value,
      });
      setLocalCollections(newCollections);
      setMappedCollecitons(newCollections);
    } else if (option.type === "Tag") {
      const tag = tags.find((t) => t._id?.toString() === option.value);
      newCollections.push({
        order: localCollections.length ? localCollections[localCollections.length - 1].order + 1 : 1,
        data: option.value,
        type: (option.type as HighlightedCollectionType) || HighlightedCollectionType.Tag,
        market: market,
        collection: tag,
        id: option.value,
      });
      setLocalCollections(newCollections);
      setMappedCollecitons(newCollections);
    } else if (option.type === "BusinessRules") {
      const rule = businessRules.find((rule) => rule._id?.toString() === option.value);
      newCollections.push({
        order: localCollections.length ? localCollections[localCollections.length - 1].order + 1 : 1,
        data: option.value,
        type: (option.type as HighlightedCollectionType) || HighlightedCollectionType.Tag,
        market: market,
        collection: rule,
        id: option.value,
      });
      setLocalCollections(newCollections);
      setMappedCollecitons(newCollections);
    } else {
      toast.info("Unknown type");
    }
  };

  const openDialogFn = (collection: HighlightedCollectionWithCollection) => {
    setSelectedCollection(collection);
    setOpenDialog(true);
  };

  const collectionColumns = useMemo<ColumnDef<HighlightedCollectionWithCollection>[]>(
    () => [
      {
        id: "drag-handle",
        header: "",
        cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
      },
      {
        id: "name",
        header: "Name",
        onClick: (event: any, row: any) => {
          openDialogFn(row.original);
        },
        cell: ({ row }) => row.original.collection?.name,
      },
      {
        id: "type",
        header: "Type",
        cell: ({ row }) => row.original.type,
      },
      {
        id: "publishedRewards",
        header: "Published rewards",
        onClick: (event: any, row: any) => {
          openDialogFn(row.original);
        },
        cell: ({ row }) => row.original.collection?.publishedCount,
      },
      {
        id: "unPublishedRewards",
        header: "Unpublished rewards",
        onClick: (event: any, row: any) => {
          openDialogFn(row.original);
        },
        cell: ({ row }) => row.original.collection?.unpublishedCount,
      },
      {
        id: "addCombobox",
        header: "",
        cell: ({ row }) => {
          return (
            <div className="flex justify-end">
              <Button
                variant={"ghost"}
                onClick={(event) => {
                  event.stopPropagation();
                  deleteCollectionFn(row.id);
                }}
              >
                <Icon icon="Close" />
              </Button>
            </div>
          );
        },
      },
    ],
    [],
  );

  const table = useReactTable({
    data: localCollections,
    columns: collectionColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row) => row.id || "",
  });

  function deleteCollectionFn(id: string) {
    setLocalCollections((collections) => {
      const index = collections.findIndex((collection) => collection.id === id);
      const newCollections = [...collections];
      if (index > -1) {
        newCollections.splice(index, 1);
      }
      return newCollections;
    });
    setMappedCollecitons((collections: HighlightedCollectionWithCollection[]) => {
      const index = collections.findIndex((collection) => collection.id === id);
      const newCollections = [...collections];
      if (index > -1) {
        newCollections.splice(index, 1);
      }
      return newCollections;
    });
  }

  // reorder rows after drag & drop
  function handleDragEnd(event: DragEndEvent) {
    const { active, over }: { active: any; over: any } = event;

    if (active && over && active.id !== over.id) {
      const oldIndex = localCollections.findIndex((collection) => collection.id === active.id);
      const newIndex = localCollections.findIndex((collection) => collection.id === over.id);
      const newOrder = arrayMove(localCollections, oldIndex, newIndex);
      newOrder.map((col, index) => {
        col.order = index + 1;
        return col;
      });
      setLocalCollections(newOrder);
      setMappedCollecitons(newOrder);
    }
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between">
        <h3>Highlighed Collections</h3>
        <div className="flex justify-end">
          <Combobox
            className="w-60"
            searchName="Category or Tag"
            placeholder="Add to Collections"
            options={mappedOptions}
            onChange={createCollectionFn}
            value={""}
          />
        </div>
      </div>

      <DnDDataTable
        table={table}
        columns={collectionColumns}
        handleDragEnd={handleDragEnd}
        dataIds={localCollections.map((collection) => collection.id)}
        hidePagination
      />
      <RewardOrderPopup
        parent={selectedCollection?.collection || null}
        parentType={selectedCollection?.type || HighlightedCollectionType.Tag}
        refetch={refetchMarketplace}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        showDelete={false}
      />
    </div>
  );
}
