 
import { useEffect } from "react";
import { createFileRoute, redirect, useRouter } from "@tanstack/react-router";
import { z } from "zod";

import SplashPage from "~/pages/Splash/Splash";
import { useAuth } from "~/providers/auth";

const fallback: "/dashboard" = "/dashboard" as const;

export const Route = createFileRoute("/setauth")({
  validateSearch: z.object({
    tokens: z.string().catch(""),
    additional: z.string().catch(""),
    next_url: z.string().optional().catch("/dashboard"),
  }),
  beforeLoad: ({ context, search }) => {
    if (context.auth.isAuthenticated) {
      throw redirect({ to: search.next_url || fallback });
    }
  },
  component: () => <AuthPage />,
});

function AuthPage() {
  const auth = useAuth();
  const router = useRouter();
  const navigate = Route.useNavigate();
  const search = Route.useSearch();
  const next_url: string | "/dashboard" = search.next_url || fallback;

  const decodedTokens = atob(search.tokens);
  const user = JSON.parse(atob(search.additional));
  const [accessToken, refreshToken] = decodedTokens.split("/");

  useEffect(() => {
    auth
      .setAuth({
        accessToken: accessToken,
        refreshToken: refreshToken,
        user: user,
      })
      .then(async () => {
        await router.invalidate();
        setTimeout(() => {
          // @ts-expect-error Expecting a weird type.
          navigate({ to: next_url });
        }, 1000);
      });
  }, []);

  return <SplashPage />;
}
