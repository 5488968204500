import { useState } from "react";
import { Link } from "@tanstack/react-router";

import { SourceType, TransactionWithAdditionalData } from "~/api/model";
import formatDate from "~/helpers/date-formatting";
import { ListPage } from "~/oldComponents/layout/ListPage";
import { FormConfig, PageConfig, PaginationConfig, TableConfig } from "~/oldComponents/layout/types";

type Props = {
  staticUserId?: boolean;
  initUserId?: string;
  initSourceType?: string;
  initOfferId?: string;
  pageNumber: number;
  limit: number;
  handleSubmit: any;
  setPageNumber: any;
  setLimit: any;
  items: TransactionWithAdditionalData[];
  hasNext: boolean;
  hasPrev: boolean;
  total: number;
  loading: boolean;
};

export function TrasnactionTable({
  staticUserId,
  initUserId,
  initSourceType,
  initOfferId,
  pageNumber,
  limit,
  handleSubmit,
  setPageNumber,
  setLimit,
  items,
  hasNext,
  hasPrev,
  total,
  loading,
}: Props) {
  const [userid, setUserid] = useState<string | undefined>(initUserId);
  const [sourceType, setSourceType] = useState<string | undefined>(initSourceType);
  const [offerId, setOfferId] = useState<string | undefined>(initOfferId);

  function handle(event: any) {
    event?.preventDefault();
    handleSubmit(userid, sourceType, offerId);
  }

  const page: PageConfig = {
    title: "Transactions",
    description: staticUserId
      ? "Find all transactions of the user"
      : "Find all transactions in the system. Here you can search for a specific transaction",
    tableUpload: {
      fileName: "transactions",
      data: items,
    },
  };
  const form: FormConfig = {
    fields: [
      {
        name: "source-type",
        label: "Source type:",
        helpText: "Filter the type of transaction",
        type: "search-select",
        change: (data: any) => {
          setSourceType(data);
        },
        options: Object.keys(SourceType).map((key) => ({ name: key, value: key })),
        empty: "All",
        value: sourceType,
      },
      {
        name: "userid",
        label: "User ID:",
        helpText: "The ID of the user that the transaction is related to.",
        type: "text",
        disabled: staticUserId,
        change: (e: any) => {
          setUserid(e.target.value);
        },
        value: userid,
      },
      {
        name: "offer-id",
        label: "Offer ID:",
        helpText: "The ID of the offer that the transaction is related to. This will only include transactions that are related to an offer.",
        type: "text",
        change: (e: any) => {
          setOfferId(e.target.value);
        },
        value: offerId,
      },
    ],
    onSubmit: handle,
    submitText: "Search Transactions",
    name: "transaction-filter",
  };

  const table: TableConfig = {
    tableBuild: [
      {
        headerTitle: "User Id",
        format: (transaction: TransactionWithAdditionalData) => (
          <Link className="link" to={`/users/${transaction.transaction.userId}`} target="_blank">
            {transaction.transaction.userId}
          </Link>
        ),
      },
      { headerTitle: "Amount", format: (transaction: TransactionWithAdditionalData) => transaction.transaction.amount },
      { headerTitle: "Source type", format: (transaction: TransactionWithAdditionalData) => transaction.transaction.source.type },
      {
        headerTitle: "More info",
        format: (transaction: TransactionWithAdditionalData) => {
          if (transaction.order) {
            return (
              <>
                Offer:{" "}
                {transaction.order.offerSnapshot?.bonus ? (
                  <Link
                    to={"/$market/marketplace/rewards/bonus/$rewardId"}
                    params={{ market: transaction.order.offerSnapshot?.country || "SE", rewardId: transaction.order.offerId }}
                    target="_blank"
                    className="link"
                  >
                    {transaction.order.offerSnapshot?.titleV2 || "No title found"}
                  </Link>
                ) : (
                  <Link
                    to={"/$market/marketplace/rewards/$rewardId"}
                    params={{ market: transaction.order.offerSnapshot?.country || "SE", rewardId: transaction.order.offerId }}
                    target="_blank"
                    className="link"
                  >
                    {transaction.order.offerSnapshot?.titleV2 || "No title found"}
                  </Link>
                )}
                <br />
                Coupon: {transaction.coupon?.code || transaction.coupon?.url || ""}
              </>
            );
          } else if (transaction.step) {
            return `${transaction.step.trackedAmount?.toLocaleString() || 0} Steps`;
          } else if (transaction.admin) {
            return (
              <Link to={`/users/${transaction.admin._id}`} target="_blank" className="link">
                {transaction.admin.email || transaction.admin.profile?.email}
              </Link>
            );
          } else if (transaction.user_invite) {
            const isInviter = transaction.transaction.source.type === SourceType.UserInvite;
            const inviteCreatedByAdmin = !!transaction.user_invite?.inviteLinkId;
            const isInviteDeleted =
              !transaction.user_invite?.inviterId && !transaction.user_invite?.inviteeId && !transaction.user_invite?.inviteLinkId;

            return (
              <>
                {inviteCreatedByAdmin ? (
                  <>
                    <p>Invite link:</p>
                    <Link to={`/invite-links/${transaction.user_invite?.inviteLinkId}`} target="_blank" className="link">
                      {transaction.user_invite?.inviteLinkId}
                    </Link>
                  </>
                ) : (
                  <>
                    <p>{isInviteDeleted ? "-" : isInviter ? "Invitee:" : "Inviter:"}</p>
                    <Link
                      to={`/users/${isInviter ? transaction.user_invite?.inviteeId : transaction.user_invite?.inviterId}`}
                      target="_blank"
                      className="link"
                    >
                      {isInviter ? transaction.user_invite?.inviteeId : transaction.user_invite?.inviterId}
                    </Link>
                  </>
                )}
              </>
            );
          }

          return "-";
        },
      },
      { headerTitle: "Action date", format: (transaction: TransactionWithAdditionalData) => formatDate(transaction.transaction.actionDate || "") },
      { headerTitle: "Created at", format: (transaction: TransactionWithAdditionalData) => formatDate(transaction.transaction.createdAt || "") },
    ],
    objects: items,
  };

  const pagination: PaginationConfig = {
    page: {
      value: pageNumber,
      onChange: setPageNumber,
    },
    limit: {
      value: limit,
      onChange: setLimit,
    },
    hasNext: hasNext,
    hasPrevious: hasPrev,
    total: total,
  };

  return <ListPage page={page} table={table} form={form} pagination={pagination} loading={loading} />;
}
