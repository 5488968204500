import { useEffect, useMemo, useState } from "react";
import { Link, ReactNode } from "@tanstack/react-router";
import { ColumnDef, flexRender, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { AxiosError } from "axios";
import { ArrowUpDown, MoreHorizontal } from "lucide-react";
import { toast } from "sonner";

import { ConfirmDialog } from "@/confirm_dialog";
import { Icon } from "@/icon/icon";
import MultiSelectFormField from "@/multi_select";
import { Button } from "@/ui/button";
import { Checkbox } from "@/ui/checkbox";
import DisabledComponentTooltip from "@/ui/disable-component-tooltip";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/ui/dropdown-menu";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/ui/select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/ui/table";

import { RewardsFilter } from "./RewardsFilter";

import {
  useAddTagsToOffersV1MarketplaceBulkOffersTagsPost,
  useChangeOffersStatusV1MarketplaceBulkOffersStatusPut,
  useCreateTagV1MarketplaceTagsPost,
} from "~/api/marketplace/marketplace.gen";
import { OfferFilterStatus, OfferStatus, OfferType, UserRole } from "~/api/model";
import {
  useDeleteOfferByIdV1OffersOfferIdDelete,
  useDeleteOffersByIdsV1OffersDelete,
  useUpdateBonusOfferV1OffersBonusOfferOfferIdPut,
  useUpdateOfferV1OffersOfferIdPut,
} from "~/api/offers/offers.gen";
import { getCategoryIcon } from "~/helpers/category";
import formatDate from "~/helpers/date-formatting";
import { prepareBonusOfferDuplication, prepareBonusOfferUpdate, prepareOfferDuplication, prepareOfferUpdate } from "~/helpers/offer";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { cn } from "~/lib/utils";
import { useAuth } from "~/providers/auth";
import { OfferWithCategoriesAndTags, TagWithStatusAndRewardCount, useMarketplace } from "~/providers/marketplace";

export type RewardSearch = {
  search?: string;
  status?: OfferFilterStatus;
  rewardType?: string;
  platform?: string;
  categories?: string;
  tags?: string;
};

const Rewards = ({ navigate, search }: { navigate: any; search: RewardSearch }) => {
  const { roles } = useAuth();
  const { rewards, refetchMarketplace, marketplaceLoading, tags, market } = useMarketplace();
  const [statusFilter, setStatusFilter] = useState<OfferFilterStatus>(OfferFilterStatus.Active);

  const [mappedOffers, setMappedOffers] = useState<OfferWithCategoriesAndTags[]>([]);

  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
  const [changeStatusDialogText, setChangeStatusDialogText] = useState("");
  const [changeStatusOffer, setChangeStatusOffer] = useState<OfferWithCategoriesAndTags | null>(null);
  const [changeStatusStatus, setChangeStatusStatus] = useState<OfferStatus | null>(null);

  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [bulkActionAlertDialog, setBulkActionAlertDialog] = useState({
    open: false,
    title: "",
    confirmText: "",
    confirmAction: () => {},
  });
  const [rewardToDelete, setRewardToDelete] = useState<OfferWithCategoriesAndTags | undefined>(undefined);
  const [rowSelection, setRowSelection] = useState({});
  const [newTags, setNewTags] = useState<{ label: string; value: string }[]>([]);

  const [columnVisibility, _setColumnVisibility] = useState({
    rewardType: true,
    platformString: false,
  });

  const deleteRewardMutation = useDeleteOfferByIdV1OffersOfferIdDelete({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
      },
      onSuccess: () => {
        toast("Reward deleted successfully!");
        refetchMarketplace();
      },
    },
  });

  function deleteReward() {
    if (rewardToDelete) {
      deleteRewardMutation.mutateAsync({
        offerId: rewardToDelete.id as string,
      });
    }
  }

  const updateOfferMutation = useUpdateOfferV1OffersOfferIdPut({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
        refetchMarketplace();
      },
      onSuccess: () => {
        toast("Reward updated successfully!");
        refetchMarketplace();
      },
    },
  });

  const updateBonusOfferMutation = useUpdateBonusOfferV1OffersBonusOfferOfferIdPut({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
        refetchMarketplace();
      },
      onSuccess: () => {
        toast("Bonus Reward updated successfully!");
        refetchMarketplace();
      },
    },
  });

  const createTagMutation = useCreateTagV1MarketplaceTagsPost({
    mutation: {
      onSuccess: (tag) => {
        setNewTags([{ label: tag.name, value: tag._id || "" }, ...newTags]);
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
      },
    },
  });

  const bulkDeleteRewardsMutation = useDeleteOffersByIdsV1OffersDelete();

  function deleteSelectedRewards() {
    if (!roles.includes(UserRole.Admin_Owner)) {
      toast.error("Error", { description: "Only Admin Owners can delete rewards." });
      return;
    }
    const selectedRows = table.getSelectedRowModel().rows;
    if (selectedRows.some((row) => row.original.status != OfferStatus.Archived)) {
      toast.error("Error", { description: "Only archived rewards can be deleted." });
      return;
    }
    const selectedIds: string[] = selectedRows.map((row) => row.original.id as string);
    bulkDeleteRewardsMutation.mutate(
      { data: selectedIds },
      {
        onSuccess: () => {
          setMappedOffers((offers) => offers.filter((offer) => !selectedIds.includes(offer.id as string)));
          setRowSelection({});
          refetchMarketplace();
          toast("Rewards are now deleted.");
        },
        onError: (error: AxiosError) => {
          toast.error("Error", { description: parseFastAPIError(error) });
        },
      },
    );
  }

  const updateStatusBulk = useChangeOffersStatusV1MarketplaceBulkOffersStatusPut();

  function updateRewardsStatusBulk(status: OfferStatus, fromStatus: OfferStatus) {
    if (status === OfferStatus.Published) {
      throw new Error("Publish is not supported now.");
    }

    const selectedRows = table.getSelectedRowModel().rows;
    if (status === OfferStatus.Archived && selectedRows.some((row) => row.original.status != OfferStatus.Unpublished)) {
      toast.error("Error", { description: "Only unpublished rewards can be archived." });
      return;
    }

    if (
      status === OfferStatus.Unpublished &&
      fromStatus === OfferStatus.Published &&
      selectedRows.some((row) => row.original.status != OfferStatus.Published)
    ) {
      toast.error("Error", { description: "Only published rewards can be unpublished." });
      return;
    }

    if (
      status === OfferStatus.Unpublished &&
      fromStatus === OfferStatus.Archived &&
      selectedRows.some((row) => row.original.status != OfferStatus.Archived)
    ) {
      toast.error("Error", { description: "Only archived rewards can be unarchived." });
      return;
    }

    const selectedIds: string[] = selectedRows.map((row) => row.original.id as string);
    updateStatusBulk.mutate(
      {
        data: {
          offerIds: selectedIds,
          status: status,
          fromStatus: fromStatus,
        },
      },
      {
        onSuccess: (now) => {
          setRowSelection({});
          setMappedOffers((offers) =>
            offers.filter((offer) => {
              if (!selectedIds.includes(offer.id as string)) return offer;
              const resultOffer = {
                ...offer,
                status: status,
              };
              if (status === OfferStatus.Archived) {
                resultOffer["publishDate"] = now as string;
                resultOffer["unPublishDate"] = now as string;
              } else if (status === OfferStatus.Unpublished) {
                resultOffer["unPublishDate"] = now as string;
              }
              return resultOffer;
            }),
          );
          refetchMarketplace();
          toast("Rewards status are updated.");
        },
        onError: (error: AxiosError) => {
          toast.error("Error", { description: parseFastAPIError(error) });
        },
      },
    );
  }

  useEffect(() => {
    if (rewards) {
      const rewardsWithRewardTypeAndPlatformString = rewards.map((reward) => {
        return {
          ...reward,
          rewardType: reward.type || OfferType.Regular,
          platformString:
            reward.platform.android === true && reward.platform.ios === true ? "both" : reward.platform.android === true ? "android" : "ios",
        };
      });
      setMappedOffers(rewardsWithRewardTypeAndPlatformString);
    }
  }, [rewards]);

  useEffect(() => {
    if (market) {
      setRowSelection({});
    }
  }, [market]);

  function updateHideInList(offer: OfferWithCategoriesAndTags, hideInList: boolean) {
    if (!offer._id) return;

    setMappedOffers((offers) => {
      return offers.map((lOffer: any) => {
        if (lOffer.id === offer._id) return { ...lOffer, hideInList: hideInList };
        return lOffer;
      });
    });

    if (offer.isBonus) {
      updateBonusOfferMutation.mutateAsync({
        offerId: offer._id,
        data: {
          ...prepareBonusOfferUpdate(offer),
          hideInList: hideInList,
        },
      });
    } else {
      updateOfferMutation.mutateAsync({
        offerId: offer._id,
        data: {
          ...prepareOfferUpdate(offer),
          hideInList: hideInList,
        },
      });
    }
  }

  function prepareStatusUpdate(offer: OfferWithCategoriesAndTags, status: OfferStatus) {
    if (offer.status === status) return;

    let statusText = `Are you sure you want to change the status of the reward "${offer.titleV2}" from <br/>"${offer.status}" to "${status}"?`;

    if (offer.status === OfferStatus.Unpublished && status === OfferStatus.Published) {
      statusText += "|We will also set the publishDate to now and will set the unpublishDate in the future if that was not already the case.";
    }

    if (offer.status === OfferStatus.Published && status === OfferStatus.Unpublished) {
      statusText += "|We will also set the unpublishDate to now if that was not already the case.";
    }

    if (status === OfferStatus.Archived) {
      statusText += "|This will remove the reward from the marketplace. This will set the publish and unpublish date to now.";
    }

    setChangeStatusDialogText(statusText);
    setChangeStatusOffer(offer);
    setChangeStatusStatus(status);
    setShowChangeStatusDialog(true);
  }

  function updateStatus() {
    if (!changeStatusOffer || !changeStatusStatus) return null;
    // TODO: Update the table to reflect the new status without needing to reload the data from the backend
    //
    const additionalUpdateFields: { publishDate?: string; unPublishDate?: string } = {};
    const now = new Date().toISOString();
    const oneMonthLater = new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString();
    if (changeStatusOffer.status === OfferStatus.Unpublished && changeStatusStatus === OfferStatus.Published) {
      additionalUpdateFields["publishDate"] = now;
      if (!changeStatusOffer.unPublishDate || changeStatusOffer.unPublishDate < oneMonthLater) {
        additionalUpdateFields["unPublishDate"] = oneMonthLater;
      }
    }

    setRowSelection({});

    if (changeStatusOffer.status === OfferStatus.Published && changeStatusStatus === OfferStatus.Unpublished) {
      additionalUpdateFields["unPublishDate"] = now;
    }

    if (changeStatusStatus === OfferStatus.Archived) {
      additionalUpdateFields["publishDate"] = now;
      additionalUpdateFields["unPublishDate"] = now;
    }

    setMappedOffers((offers) => {
      return offers.map((lOffer: any) => {
        if (lOffer.id === changeStatusOffer._id) return { ...lOffer, status: changeStatusStatus, ...additionalUpdateFields };
        return lOffer;
      });
    });

    if (changeStatusOffer.isBonus) {
      return updateBonusOfferMutation.mutateAsync({
        offerId: changeStatusOffer._id as string,
        data: {
          ...prepareBonusOfferUpdate(changeStatusOffer),
          status: changeStatusStatus,
          ...additionalUpdateFields,
        },
      });
    } else {
      return updateOfferMutation.mutateAsync({
        offerId: changeStatusOffer._id as string,
        data: { ...prepareOfferUpdate(changeStatusOffer), status: changeStatusStatus, ...additionalUpdateFields },
      });
    }
  }

  const columns: ColumnDef<OfferWithCategoriesAndTags>[] = useMemo(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox checked={row.getIsSelected()} onCheckedChange={(value) => row.toggleSelected(!!value)} aria-label="Select row" />
        ),
      },
      {
        id: "actions",
        header: "Actions",
        cell: ({ row }) => {
          const reward = row.original;

          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {reward.deeplink && (
                  <DropdownMenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      navigator.clipboard.writeText(reward.deeplink as string);
                      toast("Link was successfully copied to clipboard!");
                    }}
                  >
                    Copy Deeplink
                  </DropdownMenuItem>
                )}
                {!reward.isBonus && (
                  <>
                    <DropdownMenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                        navigate({
                          to: "/$market/marketplace/rewards/$rewardId/coupons",
                          params: { rewardId: reward.id, market: reward.country },
                        });
                      }}
                    >
                      Show coupon codes
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                        navigate({
                          to: "/$market/marketplace/rewards/create",
                          params: { market: reward.country },
                          search: prepareOfferDuplication(reward),
                        });
                      }}
                    >
                      Duplicate reward
                    </DropdownMenuItem>
                  </>
                )}
                {reward.isBonus && (
                  <DropdownMenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate({
                        to: "/$market/marketplace/rewards/bonus/create",
                        params: { market: reward.country },
                        search: prepareBonusOfferDuplication(reward),
                      });
                    }}
                  >
                    Duplicate bonus reward
                  </DropdownMenuItem>
                )}
                {roles.includes(UserRole.Admin_Owner) && reward.status === OfferStatus.Archived && (
                  <DropdownMenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      setRewardToDelete(reward);
                      setOpenAlertDialog(true);
                    }}
                  >
                    Delete
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
      {
        accessorKey: "order",
        header: ({ column }) => {
          return (
            <Button variant="ghost" className="px-0" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              Order
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
      },
      {
        accessorKey: "logoUrl",
        header: "",
        cell: ({ row }) => {
          const logo: string = row.getValue("logoUrl");
          if (logo) {
            if (row.original.isBonus) {
              return (
                <Link
                  to="/$market/marketplace/rewards/bonus/$rewardId"
                  params={{ market: row.original.country, rewardId: row.original.id as string }}
                >
                  <img src={`${import.meta.env.VITE_ASSETS_DOMAIN}/${logo}`} className="h-10 min-h-10 w-10 min-w-10 cursor-pointer rounded-full" />
                </Link>
              );
            }
            return (
              <Link to="/$market/marketplace/rewards/$rewardId" params={{ market: row.original.country, rewardId: row.original.id as string }}>
                <img src={`${import.meta.env.VITE_ASSETS_DOMAIN}/${logo}`} className="h-10 min-h-10 w-10 min-w-10 cursor-pointer rounded-full" />
              </Link>
            );
          }
        },
      },
      {
        accessorKey: "fullTitle",
        header: ({ column }) => {
          return (
            <Button variant="ghost" className="px-0" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              Reward
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => {
          if (row.original.isBonus) {
            return (
              <Link to="/$market/marketplace/rewards/bonus/$rewardId" params={{ market: row.original.country, rewardId: row.original.id as string }}>
                {row.original.fullTitle}
              </Link>
            );
          }
          return (
            <Link to="/$market/marketplace/rewards/$rewardId" params={{ market: row.original.country, rewardId: row.original.id as string }}>
              {row.original.fullTitle}
            </Link>
          );
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => {
          return (
            <Select onValueChange={(value: OfferStatus) => prepareStatusUpdate(row.original, value)} value={row.original.status}>
              <SelectTrigger
                className={cn(
                  "justify-center gap-1 rounded-full bg-archived p-2 text-archived-foreground",
                  row.original.status === "Published" && "bg-published text-published-foreground",
                  row.original.status === "Unpublished" && "bg-unpublished text-unpublished-foreground",
                )}
              >
                <SelectValue placeholder="Select a status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={OfferStatus.Published}>Published</SelectItem>
                <SelectItem value={OfferStatus.Unpublished}>Unpublished</SelectItem>
                <SelectItem value={OfferStatus.Archived}>Archived</SelectItem>
              </SelectContent>
            </Select>
          );
        },
        filterFn: (row, columnId, filterValue) => {
          if (filterValue === OfferFilterStatus.Active) {
            return row.original.status === OfferStatus.Published || row.original.status === OfferStatus.Unpublished;
          }
          return row.original.status === filterValue;
        },
      },
      {
        accessorKey: "hideInList",
        header: "Hidden",
        cell: ({ row }) => {
          return <Checkbox checked={row.getValue("hideInList")} onCheckedChange={(value) => updateHideInList(row.original, !!value)} />;
        },
      },
      {
        accessorKey: "price",
        header: ({ column }) => {
          return (
            <Button variant="ghost" className="px-0" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              Points
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
      },
      {
        id: "available",
        header: () => {
          return (
            <div>
              Total Inventory
              <br />
              <small>Available/Claimed</small>
            </div>
          );
        },
        cell: ({ row }) => {
          return (
            <div>
              {row.original.amount}
              <br />
              <small>
                {row.original.amount - (row.original.verified || 0)}/{row.original.verified}
              </small>
            </div>
          );
        },
      },
      {
        id: "codes",
        header: () => {
          return (
            <div>
              Codes
              <br />
              <small>Unused/Used</small>
            </div>
          );
        },
        cell: ({ row }) => (
          <div>
            {row.original.unused_coupon_count}/{(row.original.coupon_count || 0) - (row.original.unused_coupon_count || 0)}
          </div>
        ),
      },
      {
        accessorKey: "categories",
        header: () => "Categories",
        cell: ({ row }) => {
          const categories = row.original.categories;
          // TODO: Make the categories into a string that should be displayed.
          return (
            <div className="flex items-center gap-4">
              {categories.map((category) => (
                <div key={category.category} className="rounded-md bg-accent p-2 text-accent-foreground">
                  <Icon className="h-5 w-5" icon={getCategoryIcon(category.category)} />
                </div>
              ))}
            </div>
          );
        },
        filterFn: (row, _columnId, filterValue) => {
          if (filterValue && filterValue.length > 0) {
            return row.original.categories.some((category) => filterValue.includes(category.category));
          }
          return true;
        },
      },
      {
        accessorKey: "tags",
        header: () => "Tags",
        cell: ({ row }) => {
          const tags = row.original.tags;
          return tags.map((tag) => <div key={tag._id as string}>{tag.name}</div>);
        },
        filterFn: (row, _columnId, filterValue) => {
          if (filterValue && filterValue.length > 0) {
            return row.original.tags.some((tag) => filterValue.includes(tag._id));
          }
          return true;
        },
      },
      {
        id: "schedule",
        header: () => "Schedule",
        cell: ({ row }) => {
          return (
            <div className="flex flex-col gap-2">
              <div className="whitespace-nowrap">Start: {formatDate(row.original.publishDate || "") as ReactNode}</div>
              <div className="whitespace-nowrap">End: {formatDate(row.original.unPublishDate || "") as ReactNode}</div>
            </div>
          );
        },
      },
      {
        accessorKey: "rewardType",
        header: () => "Reward type",
      },
      {
        accessorKey: "platformString",
        header: () => "Platform",
      },
    ],
    [rewards, status],
  );

  const table = useReactTable({
    data: mappedOffers,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      columnFilters: [
        {
          id: "status",
          value: OfferFilterStatus.Active,
        },
      ],
      sorting: [{ desc: false, id: "order" }],
    },
    state: {
      columnVisibility: columnVisibility,
      rowSelection: rowSelection,
    },
    onRowSelectionChange: setRowSelection,
  });

  useEffect(() => {
    if (search.search) table.getColumn("fullTitle")?.setFilterValue(search.search);
    if (search.rewardType) table.getColumn("rewardType")?.setFilterValue(search.rewardType);
    if (search.platform) table.getColumn("platformString")?.setFilterValue(search.platform);
    if (search.categories) table.getColumn("categoryIdsString")?.setFilterValue(search.categories);
    if (search.tags) table.getColumn("tagNames")?.setFilterValue(search.tags);
  }, []);

  const getSelectedOffersCommonTagIds = () => {
    const selectedRowsTags = table.getSelectedRowModel().rows.map((row) => row.original.tags);
    const commonTags = selectedRowsTags.reduce((acc, tags) => {
      return acc.filter((tag) => tags.some((t) => t._id === tag._id));
    }, selectedRowsTags[0]);
    return commonTags ? commonTags.map((tag) => tag._id as string) : [];
  };

  const bulkAddOffersTagsMutation = useAddTagsToOffersV1MarketplaceBulkOffersTagsPost();

  const addTagSaveBtn = (selectedValues: string[], closePopover: () => void, clearSearch: () => void) => {
    const defaultSelectedTagIds = getSelectedOffersCommonTagIds();
    const newSelectedTagIds = selectedValues.filter((value) => !defaultSelectedTagIds.includes(value));
    const allTagIds = [...defaultSelectedTagIds, ...newSelectedTagIds];
    const offerIds = table.getSelectedRowModel().rows.map((row) => row.original.id as string);
    if (newSelectedTagIds.length > 0) {
      bulkAddOffersTagsMutation.mutate(
        {
          data: {
            market: market,
            offerIds: offerIds,
            tagIds: newSelectedTagIds,
          },
        },
        {
          onError: (error: AxiosError) => {
            toast.error("Error", { description: parseFastAPIError(error) });
          },
          onSuccess: async () => {
            toast("Tag(s) added successfully!");
            const newCommonTags = [...newTags.map((t) => ({ _id: t.value, name: t.label }) as TagWithStatusAndRewardCount), ...tags].filter((tag) =>
              allTagIds.includes(tag._id as string),
            );
            const updatedRewards = mappedOffers.map((offer) => {
              if (!offerIds.includes(offer.id as string)) return offer;
              const newTags = [...offer.tags, ...newCommonTags.filter((tag) => !offer.tags.some((t) => t._id === tag._id))].sort((a, b) =>
                a.name.localeCompare(b.name),
              );
              const newTagIds = newTags.map((tag) => tag._id as string);
              const newTagNames = newTags.map((tag) => tag.name).join(",");
              return {
                ...offer,
                tags: newTags,
                tagIds: newTagIds,
                tagNames: newTagNames,
              };
            });

            clearSearch();

            setMappedOffers(updatedRewards);

            setNewTags([]);

            refetchMarketplace();
          },
        },
      );
      closePopover();
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <RewardsFilter
        table={table}
        search={search}
        navigate={navigate}
        onStatusChange={(value: string) => {
          setRowSelection({});
          setStatusFilter(value as OfferFilterStatus);
        }}
      />
      <section className="flex items-center justify-between">
        <h3 className="leading-[2.75rem]">Rewards list</h3>
        <div className="ml-auto flex items-center gap-6">
          {Object.keys(rowSelection).length > 0 && (
            <DisabledComponentTooltip
              tooltipText="Tags cannot be added to archived rewards."
              disabled={table.getSelectedRowModel().rows.some((row: any) => row.original.status === OfferStatus.Archived)}
            >
              <MultiSelectFormField
                placeholder="+  Add tag"
                options={[
                  ...newTags.sort((a, b) => a.label.localeCompare(b.label)),
                  ...tags.map((tag) => ({ label: tag.name, value: tag._id || "" })),
                ]}
                defaultValue={[...getSelectedOffersCommonTagIds(), ...newTags.map((tag) => tag.value)]}
                newItems={newTags.map((tag) => tag.value)}
                variant={"reverse"}
                fixedDefaultValue={true}
                onValueChange={() => {}}
                showBadge={false}
                buttonOptions={{
                  showClearButton: false,
                  showCloseButton: false,
                }}
                customFooter={(options, searchValue, selectedValues, closePopover, clearSearch) => {
                  return (
                    getSelectedOffersCommonTagIds().length !== selectedValues.length && (
                      <div className="p-2">
                        <Button variant={"default"} className="h-3 w-full" onClick={() => addTagSaveBtn(selectedValues, closePopover, clearSearch)}>
                          Save
                        </Button>
                      </div>
                    )
                  );
                }}
                searchOptions={{
                  emptyState: "add",
                  addEvent: (value) => {
                    createTagMutation.mutate({ data: { name: value, market: market } });
                  },
                }}
              />
            </DisabledComponentTooltip>
          )}
          {Object.keys(rowSelection).length > 0 && (
            <DisabledComponentTooltip
              tooltipText="Published rewards can be unpublished."
              disabled={table.getSelectedRowModel().rows.some((row) => row.original.status != OfferStatus.Published)}
            >
              <Button
                size="lg"
                variant="outline"
                onClick={() =>
                  setBulkActionAlertDialog({
                    open: true,
                    title: "Are you sure you want to unpublish the selected rewards?",
                    confirmText: "Unpublish rewards",
                    confirmAction: () => updateRewardsStatusBulk(OfferStatus.Unpublished, OfferStatus.Published),
                  })
                }
              >
                Unpublish Rewards
              </Button>
            </DisabledComponentTooltip>
          )}
          {Object.keys(rowSelection).length > 0 && (
            <DisabledComponentTooltip
              tooltipText={`${statusFilter === OfferFilterStatus.Archived ? "Archived" : "Unpublished"} rewards can be ${statusFilter === OfferFilterStatus.Archived ? "unarchived" : "archived"}.`}
              disabled={table
                .getSelectedRowModel()
                .rows.some((row) =>
                  statusFilter === OfferFilterStatus.Archived
                    ? row.original.status != OfferStatus.Archived
                    : row.original.status != OfferStatus.Unpublished,
                )}
            >
              <Button
                size="lg"
                variant="default"
                onClick={() =>
                  setBulkActionAlertDialog({
                    open: true,
                    title: `Are you sure you want to ${statusFilter === OfferFilterStatus.Archived ? "unarchive" : "archive"} the selected rewards?`,
                    confirmText: `${statusFilter === OfferFilterStatus.Archived ? "unarchive" : "archive"} rewards`,
                    confirmAction:
                      statusFilter === OfferFilterStatus.Archived
                        ? () => updateRewardsStatusBulk(OfferStatus.Unpublished, OfferStatus.Archived)
                        : () => updateRewardsStatusBulk(OfferStatus.Archived, OfferStatus.Unpublished),
                  })
                }
              >
                {`${statusFilter === OfferFilterStatus.Archived ? "unarchive" : "archive"} rewards`}
              </Button>
            </DisabledComponentTooltip>
          )}
          {roles.includes(UserRole.Admin_Owner) && Object.keys(rowSelection).length > 0 && (
            <DisabledComponentTooltip
              tooltipText="Active rewards cannot be deleted."
              disabled={table.getSelectedRowModel().rows.some((row) => row.original.status != OfferStatus.Archived)}
            >
              <Button
                size="lg"
                variant="destructive"
                onClick={() =>
                  setBulkActionAlertDialog({
                    open: true,
                    title: "Are you sure you want to delete the selected rewards?",
                    confirmText: "Delete",
                    confirmAction: deleteSelectedRewards,
                  })
                }
              >
                <Icon icon="Trash" /> Delete Rewards
              </Button>
            </DisabledComponentTooltip>
          )}
        </div>
      </section>
      <div className="w-full">
        <div className="rounded-md">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className="">
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              <TableRow className="border-0">
                <TableCell className="p-3"></TableCell>
              </TableRow>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    {marketplaceLoading ? "Loading..." : "No results."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <ConfirmDialog
            openDialog={bulkActionAlertDialog.open}
            setOpenDialog={(value: boolean) => setBulkActionAlertDialog({ ...bulkActionAlertDialog, open: value })}
            title={bulkActionAlertDialog.title}
            confirmAction={bulkActionAlertDialog.confirmAction}
            confirmText={bulkActionAlertDialog.confirmText}
          />
          <ConfirmDialog
            openDialog={openAlertDialog}
            setOpenDialog={setOpenAlertDialog}
            title={`Are you sure you want to delete ${rewardToDelete?.titleV2}?`}
            confirmAction={deleteReward}
            confirmText="Delete"
          />
          <ConfirmDialog
            openDialog={showChangeStatusDialog}
            setOpenDialog={setShowChangeStatusDialog}
            title={changeStatusDialogText}
            confirmAction={updateStatus}
            confirmText={`set to ${changeStatusStatus}`}
          />
        </div>
      </div>
    </div>
  );
};

export default Rewards;
