import { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { ColumnDef, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { z } from "zod";

import { Combobox, ComboboxOption } from "@/combobox";
import { DataTable } from "@/datatable";
import { Icon } from "@/icon/icon";
import Tab from "@/tab";
import { buttonVariants } from "@/ui/button";
import { Card } from "@/ui/card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/ui/form";
import { Input } from "@/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/ui/select";
import { Switch } from "@/ui/switch";

import { UserModelOutput, UserStatus } from "~/api/model";
import { useSearchUsersV1UsersGet } from "~/api/users/users.gen";
import { CountriesContext } from "~/context/context";
import { getCountryName } from "~/helpers/country-list";
import Button from "~/oldComponents/button/Button";

export const formSchema = z.object({
  user_id: z.string().optional(),
  email: z.string().optional(),
  first_name: z.string().optional(),
  last_name: z.string().optional(),
  phonenumber: z.string().optional(),
  postal_code: z.string().optional(),
  street: z.string().optional(),
  city: z.string().optional(),
  country: z.string().nullable().optional().catch(null),
  is_cheater: z.boolean().optional(),
  is_possible_cheater: z.boolean().optional(),
  status: z.enum([UserStatus.Active, UserStatus.Deleted]).optional(),
  sort_field: z.string().optional().catch("score"),
  sort_order: z.number().optional().catch(-1),
  page: z.number().catch(1),
  limit: z.number().catch(100),
});

export type UserSearch = z.infer<typeof formSchema> & { _id?: string };

export const Route = createFileRoute("/_auth/users/")({
  validateSearch: formSchema,
  component: () => <Users />,
});

function Users() {
  const searchParams = Route.useSearch();
  const navigate = useNavigate({ from: Route.fullPath });
  const { countries } = useContext(CountriesContext);
  const [isSearching, setIsSearching] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  });

  const queryClient = useQueryClient();

  const [users, setUsers] = useState<UserModelOutput[]>([]);
  const [total, setTotal] = useState(0);

  const form = useForm<UserSearch>({
    resolver: zodResolver(formSchema),
    defaultValues: searchParams,
  });

  const isDefault = (search: any) => {
    return (
      !search.user_id &&
      !search.email &&
      !search.first_name &&
      !search.last_name &&
      !search.phonenumber &&
      !search.postal_code &&
      !search.street &&
      !search.city &&
      !search.country &&
      !search.is_cheater &&
      !search.is_possible_cheater &&
      !search.status
    );
  };

  const { data, isLoading, isFetching, status } = useSearchUsersV1UsersGet(
    {
      user_id: searchParams.user_id,
      email: searchParams.email,
      first_name: searchParams.first_name,
      last_name: searchParams.last_name,
      phone_number: searchParams.phonenumber,
      postal_code: searchParams.postal_code,
      street: searchParams.street,
      city: searchParams.city,
      country: searchParams.country,
      is_cheater: searchParams.is_cheater,
      is_possible_cheater: searchParams.is_possible_cheater,
      status: searchParams.status,
      page: searchParams.page,
      limit: searchParams.limit,
    },
    {
      query: {
        enabled: !isDefault(searchParams),
      },
    },
  );

  useEffect(() => {
    if (data) {
      setUsers(data.items);
      setTotal(data.total);
    } else {
      setUsers([]);
      setTotal(0);
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading) {
      setIsSearching(false);
    }
  }, [isLoading, isFetching, status]);

  useEffect(() => {
    if (pagination.pageIndex + 1 != searchParams.page || pagination.pageSize != searchParams.limit) {
      navigate({
        search: { ...searchParams, page: pagination.pageIndex + 1, limit: pagination.pageSize },
        replace: true,
      });
    }
  }, [pagination]);

  const columns = useMemo<ColumnDef<UserModelOutput>[]>(
    () => [
      {
        accessorKey: "actions",
        header: () => "",
        cell: ({ row }) => (
          <Link className={buttonVariants({ variant: "default" })} to="/users/$userId" params={{ userId: row.original._id as string }}>
            <Icon icon="Search" />
          </Link>
        ),
      },
      {
        accessorKey: "_id",
        header: () => "Id",
      },
      {
        accessorKey: "status",
        header: () => "Status",
      },
      {
        accessorKey: "first_name",
        header: () => "First Name",
        cell: ({ row }) => row.original.profile?.firstName || "-",
      },
      {
        accessorKey: "last_name",
        header: () => "Last Name",
        cell: ({ row }) => row.original.profile?.lastName || "-",
      },
      {
        accessorKey: "email",
        header: () => "Email",
      },
      {
        accessorKey: "address",
        header: () => "Address",
        cell: ({ row }) => (
          <div>
            {row.original.profile?.address?.street || "-"}
            <hr />
            {row.original.profile?.address?.city} - {row.original.profile?.address?.postalCode}
            <hr />
            {getCountryName(row.original.profile?.address?.country) || "-"}
          </div>
        ),
      },
      {
        accessorKey: "phonenumber",
        header: () => "Phonenumber",
      },
      {
        accessorKey: "walletBalance",
        header: () => "Wallet Balance",
      },
      {
        accessorKey: "is_cheater",
        header: () => "Is Cheater",
        cell: ({ row }) => (row.original.roles?.includes("Cheater") ? "Yes" : "No"),
      },
      {
        accessorKey: "is_possible_cheater",
        header: () => "Is Possible Cheater",
        cell: ({ row }) => (row.original.roles?.includes("Cheater") ? "Yes" : "No"),
      },
    ],
    [],
  );

  const table = useReactTable({
    data: users,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    rowCount: total,
    onPaginationChange: setPagination,
    state: {
      pagination: pagination,
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    queryClient.invalidateQueries({ queryKey: ["/v1/users/"] });
    setIsSearching(true);
    setTimeout(() => {
      navigate({ search: values, replace: true });
    }, 300);
  }

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section className="flex items-start justify-between">
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Users</h2>
          <p className="text-foreground">
            Find all users in the system. This page is NOT meant to be used for analytics.
            <br />
            <b>For searching we require one of the fields to be filled in.</b>
            <br />
            <br />
            The email field will be searching with Regex. This means that some characters need to be escaped. You can do this by adding a \ before the
            character.
            <br />
            The following characters need to be escaped: . ^ $ * + ? {} [ ] \ | ( )<br />
            Example: \.com will search for .com
          </p>
        </div>
      </section>
      <div>
        <div className="flex w-full gap-2">
          <Tab active={true} to="/users" title="Users" />
          <Tab active={false} to="/users/test" title="Test Users" />
        </div>
        <Card className="flex flex-col gap-10 bg-card px-4 py-10 text-card-foreground">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
              <div className="grid grid-cols-3 gap-4">
                <FormField
                  control={form.control}
                  name="user_id"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">User ID:</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} maxLength={50} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">Email:</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} maxLength={50} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="first_name"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">First Name:</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} maxLength={50} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="last_name"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">Last Name:</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} maxLength={50} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="phonenumber"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">Phonenumber:</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} maxLength={50} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="street"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">Street:</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} maxLength={50} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="postal_code"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">Postal Code:</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} maxLength={50} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="city"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">City:</FormLabel>
                      <FormControl>
                        <Input placeholder="" {...field} maxLength={50} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="country"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">Country:</FormLabel>
                      <FormControl>
                        <Combobox
                          searchName="Country"
                          placeholder="Select"
                          options={[{ value: undefined, label: "All" }, ...countries]}
                          onChange={(value: ComboboxOption) => {
                            if (value.value) {
                              field.onChange(value.value);
                            } else {
                              form.setValue("country", null);
                            }
                          }}
                          value={field.value}
                          showFlag={true}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="status"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">status:</FormLabel>
                      <FormControl>
                        <Select
                          onValueChange={(value) => {
                            if (value === "all") {
                              form.setValue("status", undefined);
                            } else {
                              form.setValue("status", value as UserStatus);
                            }
                          }}
                          defaultValue={field.value}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="All" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="all" onClick={() => form.setValue("status", undefined)}>
                              All
                            </SelectItem>
                            <SelectItem value={UserStatus.Active}>{UserStatus.Active}</SelectItem>
                            <SelectItem value={UserStatus.Deleted}>{UserStatus.Deleted}</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="is_cheater"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">Is Cheater</FormLabel>
                      <FormControl>
                        <Switch checked={field.value} onCheckedChange={(value) => field.onChange(value)} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="is_possible_cheater"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">Is Possible Cheater</FormLabel>
                      <FormControl>
                        <Switch checked={field.value} onCheckedChange={(value) => field.onChange(value)} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex justify-end gap-6">
                <Button type="submit" disabled={isLoading || isFetching || isSearching}>
                  {isLoading || isFetching || isSearching ? "Loading" : "Search"}
                </Button>
              </div>
            </form>
          </Form>

          <DataTable
            table={table}
            columns={columns}
            loading={isLoading || isFetching || isSearching}
            emptyState={
              isDefault(searchParams) ? "Please fill in one of the fields to start searching" : "No results found. Please ajust the filters"
            }
          />
        </Card>
      </div>
    </section>
  );
}
