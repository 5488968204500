import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { toast } from "sonner";
import { z } from "zod";

import { Combobox } from "@/combobox";
import { DatePicker } from "@/datepicker";
import { Button, buttonVariants } from "@/ui/button";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/ui/form";
import { Input } from "@/ui/input";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from "@/ui/sheet";

import { useCreateBusinessRuleV1MarketplaceBusinessRulesPost } from "~/api/marketplace/marketplace.gen";
import { BusinessRuleType } from "~/api/model";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { useMarketplace } from "~/providers/marketplace";

const formSchema = z
  .object({
    type: z.enum([BusinessRuleType.All, BusinessRuleType.MostSold, BusinessRuleType.NewUserSpecial]),
    name: z.string().min(2).max(50),
    startDateFilter: z.date().optional(),
    endDateFilter: z.date().optional(),
    amountOfRewards: z.number().int().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.type === BusinessRuleType.NewUserSpecial) {
      if (data.startDateFilter === undefined) {
        // I took a best guess at the messages but it's not my native language
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["startDateFilter"],
          message: "When the type is NewUserSpecial, the field startDateFilter is required",
        });
      }
      if (data.endDateFilter === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["endDateFilter"],
          message: "When the type is NewUserSpecial, the field endDateFilter is required",
        });
      }
      if (data.amountOfRewards !== undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["amountOfRewards"],
          message: "When the type is NewUserSpecial, the field amountOfRewards is not allowed",
        });
      }
    }
    if (data.type === BusinessRuleType.MostSold) {
      if (data.startDateFilter !== undefined) {
        // I took a best guess at the messages but it's not my native language
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["startDateFilter"],
          message: "When the type is MostSold, the field startDateFilter is not allowed",
        });
      }
      if (data.endDateFilter !== undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["endDateFilter"],
          message: "When the type is MostSold, the field endDateFilter is not allowed",
        });
      }
      if (data.amountOfRewards === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["amountOfRewards"],
          message: "When the type is MostSold, the field amountOfRewards is required",
        });
      }
    }
    if (data.type === BusinessRuleType.All) {
      if (data.startDateFilter !== undefined) {
        // I took a best guess at the messages but it's not my native language
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["startDateFilter"],
          message: "When the type is All, the field startDateFilter is not allowed",
        });
      }
      if (data.endDateFilter !== undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["endDateFilter"],
          message: "When the type is All, the field endDateFilter is not allowed",
        });
      }
      if (data.amountOfRewards !== undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["amountOfRewards"],
          message: "When the type is All, the field amountOfRewards is not allowed",
        });
      }
    }
  });

export const BusinessRuleCreateSheet = ({ setLoading }: any) => {
  const { market, refetchMarketplace } = useMarketplace();

  const [open, setOpen] = useState(false);

  const createBusinessRule = useCreateBusinessRuleV1MarketplaceBusinessRulesPost({
    mutation: {
      onError: (error) => {
        toast.error("Error", { description: parseFastAPIError(error) });
      },
      onSuccess: () => {
        toast.success("Business rule created");
        setLoading(true);
        refetchMarketplace();
        setOpen(false);
      },
    },
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      type: BusinessRuleType.All,
      name: "",
    },
  });

  const formData = useWatch({
    control: form.control,
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    createBusinessRule.mutate({
      data: {
        market: market,
        type: values.type,
        name: values.name,
        startDateFilter: values.startDateFilter ? format(values.startDateFilter, "yyyy-MM-dd") : undefined,
        endDateFilter: values.endDateFilter ? format(values.endDateFilter, "yyyy-MM-dd") : undefined,
        amountOfRewards: values.amountOfRewards,
      },
    });
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger>
        <div className={buttonVariants({ variant: "default" })}>Create new</div>
      </SheetTrigger>
      <SheetContent side="bottom">
        <SheetHeader>
          <SheetTitle>Creat e a new Business Rule?</SheetTitle>
          <SheetDescription>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-6">
                <FormField
                  control={form.control}
                  name="type"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Rule type</FormLabel>
                      <FormControl>
                        <Combobox
                          searchName={"Search"}
                          placeholder={"Rule type"}
                          options={[
                            {
                              label: BusinessRuleType.All,
                              value: BusinessRuleType.All,
                            },
                            {
                              label: BusinessRuleType.MostSold,
                              value: BusinessRuleType.MostSold,
                            },
                            {
                              label: BusinessRuleType.NewUserSpecial,
                              value: BusinessRuleType.NewUserSpecial,
                            },
                          ]}
                          onChange={(option) => field.onChange(option.value)}
                          value={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input placeholder="name" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {formData.type === BusinessRuleType.NewUserSpecial && (
                  <>
                    <FormField
                      control={form.control}
                      name="startDateFilter"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Start date filter</FormLabel>
                          <FormControl>
                            <DatePicker
                              showHourPicker={false}
                              defaultValue={field.value}
                              onChange={(date) => {
                                if (date) form.setValue("startDateFilter", date);
                              }}
                              ref={field.ref}
                            />
                          </FormControl>
                          <FormDescription>
                            Optional. This field is used for the {BusinessRuleType.NewUserSpecial}. To target users created between the start date and
                            the end date.
                          </FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="endDateFilter"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>End date filter</FormLabel>
                          <FormControl>
                            <DatePicker
                              showHourPicker={false}
                              defaultValue={field.value}
                              onChange={(date) => {
                                if (date) form.setValue("endDateFilter", date);
                              }}
                              ref={field.ref}
                            />
                          </FormControl>
                          <FormDescription>
                            Optional. This field is used for the {BusinessRuleType.NewUserSpecial}. To target users created between the start date and
                            the end date.
                          </FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </>
                )}
                {formData.type === BusinessRuleType.MostSold && (
                  <FormField
                    control={form.control}
                    name="amountOfRewards"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Maximum amount of rewards</FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            placeholder="Maximum amount of rewards to display"
                            {...field}
                            onChange={(e) => field.onChange(+e.target.value)}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
                <Button type="submit">Submit</Button>
              </form>
            </Form>
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};
