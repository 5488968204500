 
import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { toast } from "sonner";

import { Icon } from "./icon/icon";
import { Button } from "./ui/button";

import { useUploadImageV1UploadImagePost } from "~/api/images/images.gen";
import { ImageType } from "~/api/model";
import { validateImageDimensions } from "~/helpers/images";
import { parseFastAPIError } from "~/helpers/parse-errors";

const ImageUploader: any = React.forwardRef<
  any,
  {
    imageType: ImageType;
    onChange: any;
    defaultImage: string | null;
    children: React.ReactElement;
  }
>(({ imageType, onChange, defaultImage, children }, ref) => {
  const [file, setFile] = useState<Blob | null>(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(defaultImage);

  const uploadImageMutation = useUploadImageV1UploadImagePost({
    mutation: {
      onSuccess: (data) => {
        setUploadedImageUrl(data);
        toast.success("Image uploaded successfully");
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
      },
    },
  });

  useEffect(() => {
    if (file) {
      handleUpload();
    }
  }, [file]);

  useEffect(() => {
    onChange(uploadedImageUrl);
  }, [uploadedImageUrl]);

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file || !imageType) {
      toast.error("Error", { description: "Please select a file and an image type" });
      return;
    }

    validateImageDimensions(imageType, file, toast)
      .then(async () => {
        uploadImageMutation.mutate({
          data: {
            file: file,
            image_type: imageType,
          },
        });
      })
      .catch(() => {
        return false;
      });
  };

  return (
    <div className="flex items-start gap-4">
      {uploadedImageUrl && children}
      <div className="relative">
        <input
          className="absolute h-full w-full cursor-pointer opacity-0"
          type="file"
          accept=".jpg,.jpeg,.png,.gif"
          onChange={handleFileChange}
          ref={ref}
        />
        <Button className="p-2" type="button">
          {uploadedImageUrl ? <Icon icon="Edit" /> : <Icon icon="Add" />}
        </Button>
      </div>
    </div>
  );
});

export default ImageUploader;
