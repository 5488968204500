import { useContext } from "react";
import { useNavigate, useRouterState } from "@tanstack/react-router";

import { Combobox, ComboboxOption } from "@/combobox";
import { Icon } from "@/icon/icon";
import { Button } from "@/ui/button";

import steplerIcon from "~/assets/icons/stepler-logo.png";
import { CountriesContext } from "~/context/context";

type Props = {
  onMenuChange: () => void;
};

const Header = ({ onMenuChange }: Props) => {
  const router = useRouterState();
  const params: any = router.matches[router.matches.length - 1].params;
  const { countries } = useContext(CountriesContext);
  const navigate = useNavigate();

  function setSelectedCountry(value: string) {
    navigate({
      to: router.matches.at(-1)?.fullPath,
      params: { market: value },
    });
  }

  return (
    <div className="flex h-16 items-center justify-between bg-header px-2.5 py-4 drop-shadow-sm">
      <section className="flex items-center gap-4">
        <Button variant="ghost" size="icon" onClick={onMenuChange}>
          <Icon icon="Menu" className="h-8 w-8" />
        </Button>
        <img src={steplerIcon} className="h-8" />
        <div className="rounded-md bg-purple-600 p-2 capitalize text-white">{import.meta.env.VITE_REACT_APP_NODE_ENV}</div>
      </section>
      <section>
        {params.market && (
          <Combobox
            searchName="Country"
            placeholder="Country"
            options={countries}
            onChange={(value: ComboboxOption) => {
              if (value.value) {
                setSelectedCountry(value.value);
              }
            }}
            value={params.market}
            showFlag={true}
          />
        )}
      </section>
    </div>
  );
};

export default Header;
